(function($){
	$.fn.tableeditable = function(callback, focusCallback, unfocusCallback, useroptions){
		var defaultOptions = {
			parentElement: 'tr',
			editableElement: 'td',
			legend: null
		};
		var current_cell = null,
			prev_content = '',
			skip_blur = false,
			ref = $(this),
			options = $.extend({}, defaultOptions, useroptions);

		if(document.designMode !== undefined){
			document.designMode = "off";
			document.execCommand("contentReadOnly", true, "true");
			document.execCommand("enableObjectResizing", false, "false");
			document.execCommand('enableInlineTableEditing',false,'false');
		}

		// On ajoute la une légende après le form si on a des data-editable=true
		if(options.legend !== null && $(options.editableElement+'[data-editable=true]',ref).length){
			ref.prepend('<p class="help-block">'+options.legend+'<p>');
		}

		/* ref peux concerner plusieurs "table"
		* un TAB sur la dernière cellule fera passer sur la table suivante
		* It's not a bug, it's feature
		* pour rester sur la même table, il faudrait dans la fonction changeEdition() utiliser "current_cell.parents('table:first')" à la place de "ref"
		*/

        $(ref).delegate(options.editableElement+'[data-editable=true]','click',function(e){
            if (!$(this).hasClass('edit-on') /*&& $('div', this).attr('contenteditable') != 'true'*/) {
                selectCell($(this));
            }
        }).delegate(options.editableElement+'[data-editable=true]', 'blur', function (e) {
            if (!skip_blur) {
                current_cell.trigger('beforeChange');
                validEdition();
            } else {
                skip_blur = false;
            }
        }).delegate('#div-current-edit', 'paste', function (e) { // pour éviter de coller du contenu html
            e.preventDefault();
            var contentOnBlur = (e.originalEvent || e).clipboardData.getData('text/plain');
            contentOnBlur = contentOnBlur.replace(/(<([^>]+)>)/ig,'');
            document.execCommand('insertText', false, contentOnBlur);
        });

		function selectCell(cell){
			if(current_cell != null){
				validEdition(current_cell);
			}
			current_cell = cell;
			if(cell.attr('accept-br')){
				prev_content = cell.html();
			}else{
				prev_content = cell.text();
			}
			startEdition(current_cell);
		}

        function startEdition(cell){
            if (focusCallback != undefined) {
                focusCallback(current_cell);
            }
            cell.addClass('edit-on');//onfocus="document.execCommand('selectAll', false, null); pour sélectionner le texte au focus
            var celldiv = $('<div id="div-current-edit"'+ (cell.html().length !== 0 ? 'onfocus="document.execCommand(\'selectAll\', false, null);' : '') +'">'  + cell.html() + '</div>');
            cell.html(celldiv);
            celldiv.attr({'contenteditable':true,'spellcheck':true})//.focus();
            setTimeout(function() { document.getElementById('div-current-edit').focus(); }, 100);
            celldiv.keydown(function(e){
                var key = e.which || e.keyCode;

				if(cell.attr('accept-br')){

				}else{
					if(key == 9 || (key == 9 && e.shiftKey) || key == 13 || key == 38 || key == 40 || key == 27 ) {//9 : tab ; 13 : entrée
						e.preventDefault();//parce que sinon il va vraiment faire tab ou entrée
						skip_blur = true;
					}
				}

			}).keyup(function(e){
				var key = e.which || e.keyCode;

                if( key == 27 ){
                    cancelCurrentEdition();
                    skip_blur = true;
                }
                else if( cell.attr('accept-br')){
                    if( key == 13 ){
                        if(e.ctrlKey || e.metaKey){ // Ctrl/Cmd(mac) + Entrée
                            current_cell.trigger('beforeChange');
                            validEdition();
                            skip_blur = true;
                        }else{
                            return false;
                        }
                    }
                }else{
                    if( key == 9 && ! e.shiftKey && ! e.ctrlKey ){ //9: TAB ; 39 : flèche de droite ; 13 : Entrée
                        changeEdition('right');
                    }
                    else if( key == 9 && e.shiftKey && ! e.ctrlKey){
                        changeEdition('left');
                    }
                    else if( key == 38 ){
                        changeEdition('up');
                    }
                    else if( key == 40 ){
                        changeEdition('down');
                    }
                    else if( key == 13 ){
                        current_cell.trigger('beforeChange');
                        validEdition();
                    }
                }
            });
        }

        function changeEdition(direction){
            current_cell.trigger('beforeChange');
            var curx = $(options.editableElement+'[data-editable=true]',current_cell.parents(options.parentElement+':first')).index(current_cell),
                cury = $(options.parentElement,ref).has(options.editableElement+'[data-editable=true]').index(current_cell.parents(options.parentElement+':first'));
            var nextx, nexty;
            if(direction == 'right'){
                if(curx >= $(options.editableElement+'[data-editable=true]',current_cell.parents(options.parentElement+':first')).length-1){
                    // On passe à la ligne suivante
                    nextx = 0;
                    if(cury >= $(options.parentElement,ref).has(options.editableElement+'[data-editable=true]').length-1){ // on teste si dernière ligne
                        nexty = 0;
                    }else{
                        nexty = cury+1;
                    }
                }else{
                    nextx = curx+1;
                    nexty = cury;
                }
            }
            else if(direction == 'left'){
                if(curx <= 0){
                    // On passe à la ligne précédente
                    nextx = $(options.editableElement+'[data-editable=true]',current_cell.parents(options.parentElement+':first')).length-1;
                    nexty = cury-1; // pas besoin de tester si 1ère ligne (-1 renvois au dernier élém)
                }else{
                    nextx = curx-1;
                    nexty = cury;
                }
            }
            else if(direction == 'up'){
                nextx = curx;
                nexty = cury-1;
            }
            else{ //bottom
                nextx = curx;
                if(cury >= $(options.parentElement,ref).has(options.editableElement+'[data-editable=true]').length-1){ // on teste si dernière ligne
                    nexty = 0;
                }else{
                    nexty = cury+1;
                }
            }
            var next = $(options.editableElement+'[data-editable=true]', $(options.parentElement,ref).has(options.editableElement+'[data-editable=true]').eq(nexty)).eq(nextx);
            if(next.length > 0){
                if (unfocusCallback != undefined) {
                    unfocusCallback(current_cell);
                }
                selectCell(next);
            }
        }

        function cancelCurrentEdition(){
            if(current_cell.attr('accept-br')){
                current_cell.removeAttr('contenteditable').unbind('keyup').unbind('keydown').html(prev_content);//il faudrait remettre la valeur initiale
            }else{
                current_cell.removeAttr('contenteditable').unbind('keyup').unbind('keydown').text(prev_content);//il faudrait remettre la valeur initiale
            }
            if (unfocusCallback != undefined) {
                unfocusCallback(current_cell);
            }
        }

        function validEdition(){//auto-valide la modif
            if(current_cell != null){
                var valnoreplace = current_cell.find('div:first').html();
                if(current_cell.attr('accept-br')){
                    var val = current_cell.find('div:first').html().replace(/^<div>/g, "").replace(/<div></g, "\n<").replace(/<div>\n/g, "").replace(/<div>/g, "\n").replace(/<\/div>[\n]?/g, "\n").replace(/<br\s*[\/]?>[\n]?/gi, "\n");
                }else{
                    var val = current_cell.find('div:first').text();
                }
                current_cell.removeClass('edit-on');
                current_cell.html(valnoreplace);
                if(prev_content != val && callback != undefined){
                    callback(current_cell, val, prev_content);
                }
                current_cell = null;
                prev_content = '';
            }
        }
    }
})(jQuery);
